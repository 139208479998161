import React from "react"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import {
  PostHero,
  PostTitle,
  PostContent,
  PostContentLeft,
  PostContentLeftBody,
} from "../templates/post/post-styles"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Page Not Found" />
      <PostHero>
        <PostTitle
          css={css`
            text-transform: capitalize;
          `}
        >
          404: Page Not Found
        </PostTitle>
      </PostHero>
      <PostContent>
        <PostContentLeft>
          <PostContentLeftBody>
            <h1>Sorry!</h1>
            <p>
              Looks like the page you were trying to reach doesn't exist. Maybe
              try one of these instead!
            </p>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/">About Us</Link>
              </li>
              <li>
                <Link to="/gallery/">Our Gallery</Link>
              </li>
              <li>
                <Link to="/blog/">Our Blog</Link>
              </li>
            </ul>
          </PostContentLeftBody>
        </PostContentLeft>
      </PostContent>
    </>
  )
}
export default NotFoundPage
